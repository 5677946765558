const state = {
    overlayKey : 0,
    focusedElementBeforeOpenModal: null,
}

const getters = {
    overlayKey : state => state.overlayKey,
}

const mutations = {
    setOverlayKey(state, key){
        state.overlayKey = key;
    },
    setFocusedElementBeforeOpenModal(state, element) {
        state.focusedElementBeforeOpenModal = element;
    },
}

export default {
    state,
    mutations,
    getters
};