import Dates from '@/util/date.js';

const state = {
    data: [],
    metricUnit: {},
    metricUnits: [],
    records : [],
    record: {},
    periods: [],
    period: {},
    runRate : false,
    count : 0,  //the final computed count,  aka "rolling" numbers

}

const getters = {
    data        :  state => state.data,
    metricUnits :  state => state.metricUnits,
    metricUnit  :  state => state.metricUnit,
    records :      state => state.records,
    record :       state => state.record,
    periods     :  state => state.periods,
    period      :  state => state.period,
    runRate     :  state => state.runRate,
    count :        state => state.count,
}

const mutations = {
    setData(state, data){
        state.data = data;
    },

    setRecords(state){
        state.records = state.data.records;
    },

    setRecord(state, id){
        state.record = _.find(state.records, {unit: state.metricUnit.id});
    },

    setMetricUnit(state, unit){
        if(!_.isObject(unit)){
            unit = _.find(state.metricUnits, { id : unit });
        }
        state.metricUnit = unit;
    },
    setMetricUnits(state){
        if(!state.data.hasOwnProperty('metricUnits')){
            return;
        }
        state.metricUnits = _.orderBy(state.data.metricUnits, 'sortOrder');
    },

    setPeriod(state, period){
        state.period =  parseInt(period);
    },

    setPeriods(state){
        state.periods = [
            {name: 'This year', id: 0},
            {name: "Since you've been here", id: 1},
            {name: 'Since introduction', id: 2},
            {name: '2023', id: 3},
            {name: '2022', id: 4},
            {name: '2021', id: 5},
            {name: '2020', id: 6}
        ]
    },

    setRunRate(state){
        let unit = state.metricUnit;
        state.runRate = (state.record.runRate * unit.conversionFactor) / Dates.secondsInAYear;
    },

    setCount(){
        // when we have a run rate & a time period we can calculate
        let sessionStart;
        let unit = state.metricUnit;

        switch (state.period) {
            case 0:
                //since beginning of the year
                sessionStart = (state.runRate * Dates.diff());
                break;
            case 1:
                //since the user has been here
                sessionStart = state.runRate * Dates.sinceUser();
                break;
            case 2:
                //since program start
                let start = state.records[1].static * unit.conversionFactor + state.records[2].static * unit.conversionFactor + state.records[3].static * unit.conversionFactor + state.records[4].static * unit.conversionFactor;

                //the starting number + beginning of this year
                sessionStart = start + (state.runRate * Dates.diff()) ;
                break;
            case 3:
                //static prior year
                state.runRate = 0;
                //we don't care about now, just show the old static number of last year
                sessionStart = state.records[1].static * unit.conversionFactor;
                break;
            case 4:
                //static prior year
                state.runRate = 0;
                //we don't care about now, just show the old static number of last year
                sessionStart = state.records[2].static * unit.conversionFactor;
                break;
            case 5:
                //static prior year
                state.runRate = 0;
                //we don't care about now, just show the old static number of last year
                sessionStart = state.records[3].static * unit.conversionFactor;
                //sessionStart = state.record.static * unit.conversionFactor + state.record.static * unit.conversionFactor;
                break;
            case 6:
                //static prior year
                state.runRate = 0;
                //we don't care about now, just show the old static number of last year
                sessionStart = state.records[4].static * unit.conversionFactor;
                //sessionStart = state.record.static * unit.conversionFactor + state.record.static * unit.conversionFactor;
                break;
        }

        state.count = sessionStart;
    }

}

const actions = {
    getData({commit}){
         return axios.get('/tvd-data.json')
        .then(function(response) {
            if(!response.data){
                commit('setData', null);
                return
            }
            //fill our main object
            commit('setData', response.data);
            commit('setMetricUnits');
            commit('setRecords');
            commit('setPeriods');

        })
        //ERROR
        .catch(function(error) {
            commit('setData', null);
            console.log(error);
        });
    },
    setMetricUnitState({commit}, unit){

        commit( 'setMetricUnit', unit );
        commit( 'setRecord', unit );
        commit( 'setRunRate' );
        commit( 'setCount' );

    },
    setPeriodState({commit}, period){
        commit( 'setPeriod', period );
        commit( 'setRunRate' );
        commit( 'setCount' )
    },
    getCounterInterval({commit}, value){
        state.count = state.count + (state.runRate / 20) //this is ran every 50ms.  1000ms in a second.  So 1000/50 = 20
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};