<template>
    <div :title="selectedName">
        <label for="technology">Technology</label>
            <div :class="[multipleOptions ? 'select' : 'one_option select']">
            <select id="technology" v-model="selected">
                <option disabled="" value="default">Select a technology</option>
                <option v-for="option in technologies" v-bind:value="option.id">{{option.name}}</option>
            </select>
            <div class="select-styled-container"><div class="select-styled">{{selectedName}}</div></div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url';
export default {
     data : function () {
        return {
        }
    },
    mounted(){
        //this.getData();
    },
    computed : {
        ...mapGetters([
            'technologies',
            'technology',
        ]),
        multipleOptions : function(){
            if(!this.technologies){
                return;
            }

            if(! Array.isArray(this.technologies)){
                return;
            }

            if(this.technologies.length <= 1){
                return;
            }

            return true;

        },
        selectedName :function() {

            if(!this.technologies){
                return;
            }

            let match = _.first(_.filter(this.technologies, { id : this.selected }));

            if(!match){
                //return _.get(_.first(this.technologies), "name");
                return "--";
            }

            return match.name;
        },
        selected : {
            get () {
                return this.technology;
            },
            set (value) {
                this.setTechnologyState(value);
            }
        }
    },
    methods : {
        ...mapActions(
            [
                'setTechnologyState'
            ]
        )
    },
    watch : {
        technologies : function(){

            let match = _.find(this.technologies, { id : URL.hash.technology });

            if(match){
                //this.setTechnologyState(match);
                return;
            }

            this.setTechnologyState( _.get(_.first(this.technologies), "id") );

        }
    }
}
</script>