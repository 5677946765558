import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require('./sass/app.scss');

window.axios = require('axios');
window.lodash = require('lodash');


//modules/
import StateToHash from '@/modules/StateToHash.js';
import StateToFontSize from '@/modules/StateToFontSize.js';

new StateToHash();
new StateToFontSize()

Vue.config.productionTip = false

let vApp = new Vue({
  mounted(){

  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')

//router.onReady(() =>{
//    vApp.$mount('#app')
//})