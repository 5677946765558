<template>
	<div class="application_selector">
		<p class="d-none">Tracks water, energy, waste and air benefits delivered to customers through key Ecolab technologies.</p>

		<div class="application">
			<application-select></application-select>
		</div>
		<div class="technology">
			<technology-select></technology-select>
		</div>
		<div class="region">
			<region-select></region-select>
		</div>
	</div>
</template>

<script>

import applicationSelect from '@/components/customer-impact/ApplicationSelect.vue';
import technologySelect from '@/components/customer-impact/TechnologySelect.vue';
import regionSelect from '@/components/customer-impact/RegionSelect.vue';
import {mapGetters} from 'vuex';
export default {
	data : function () {
		return {
			showOverflow : true,

		}
	},
	computed : {
		...mapGetters([
			'iframe'
		])
	},
	components : {
		applicationSelect,
		technologySelect,
		regionSelect
	}
}
</script>