import Dates from '@/util/date.js';

const state = {
    data: [],
    years: [],
    staticValues : [],
    runRate : false,
    count : 0,
    chartColorProjected: "#cce4f4",
    chartColorActual: "#007ac9",
    number: 0
}

const getters = {
    data                : state => state.data,
    years               : state => state.years,
    staticValues        : state => state.staticValues,
    runRate             : state => state.runRate,
    count               : state => state.count,
    chartColorProjected : state => state.chartColorProjected,
    chartColorActual    : state => state.chartColorActual,
    number              : state => state.number
}

const mutations = {
    setData(state, data){
        if(data.hasOwnProperty('food')){
            state.data = data.food;
        }
    },
    setYears(state){
        if(state.data.hasOwnProperty('years')){
            state.years = _.orderBy(state.data.years, "name", "asc");
        }
    },
    setStaticValues(state){
        //projected or actual values per year
        //in the main eroi calc we have static, startingpoint, and runrate.  But I don't see how that fits here
        if(!state.years){
            return;
        }
        state.staticValues =  _.map(state.years, (year) =>{
            return year.value
        });
    },
    //set this when you know what value you want to count/rolling numbers.  Currently that is for the year 2021
    setRunRate(state, yearToCalculate){
        if(!state.years){
            return;
        }

        let year = _.find(state.years, {name : yearToCalculate})
        if (year === undefined){
            year = _.find(state.years, {name : yearToCalculate - 1})
        }
        state.runRate = year.value / Dates.secondsInAYear;
    },

    setCount(){
        state.count = (state.runRate * Dates.diff());
        state.number = (state.runRate * Dates.diff());
    }
}

const actions = {
    getData({commit}){
         return axios.get('/goals.json')
        .then(function(response) {
            if(!response.data){
                commit('setData', null);
                return
            }

            //fill our main object
            commit('setData', response.data);
            commit('setYears');
            commit('setStaticValues');


        })
        //ERROR
        .catch(function(error) {
            commit('setData', null);
            console.log(error);
        });
    },
    getCounterInterval({commit}){
        state.count = (state.count + (state.runRate / 20)) //this is ran every 50ms.  1000ms in a second.  So 1000/50 = 20
    },
    getNumberInterval({commit}){
        state.number = (state.number + (state.runRate / 20)) //this is ran every 50ms.  1000ms in a second.  So 1000/50 = 20
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};