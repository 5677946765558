<template>
	<header>
		<div class="row align-items-center justify-content-center">
			<ul class="d-flex p-0 m-0">
				<li class="col-auto">
					<div class="logo">
						<img src="@/img/logo.jpg" alt="eRoi by Ecolab logo"/>
					</div>
				</li>
				<li class="col-auto title_col">
					<div class="title">
						<img src="@/img/wordmark.svg" alt="Customer Impact Counter" title="Customer Impact Counter"/>
					</div>
				</li>
			</ul>
			<div class="col d-md-none">
				<div class="dropdown text-right">
					<button class="btn dropdown-toggle" title="menu" type="button" id="overflowMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="toggleOverflowMenu">
						<svg viewBox="0 0 24 24"><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
					</button>
					<div class="dropdown-menu" aria-labelledby="overflowMenu" v-bind:class="{ show: menuOpen }">
						<div class="dropdown-item" @click="showOverlay('whatIsEroi')">What is eROI?</div>
						<div class="dropdown-item" @click="showOverlay('aboutThisTool')">About this tool</div>
						<div class="dropdown-item" @click="showOverlay('liveCounter')">What is the Live Counter?</div>
						<div class="dropdown-item" @click="showOverlay('tvd')">What is Total Value Delivered?</div>
					</div>
				</div>
			</div>
			<div class="col-md align-self-stretch">
				<nav class="" aria-label="primary">
					<div class="nav nav-tabs justify-content-center justify-content-md-end" id="nav-tab" role="tablist">
						<div class="nav-item" v-for="(item, index) in navItems" :key="index">
							<router-link
									ref="navItems"
									class="nav-link d-flex align-items-center"
									:tabindex="(currentPath === item.to || (item.to === '/goals' && currentPath.includes('/goals'))) ? 0 : -1"
									:class="(currentPath === item.to || (item.to === '/goals' && currentPath.includes('/goals'))) ? 'active' : ''"
									:id="item.id"
									@keydown.left.native="onTabNavigate(index - 1)"
									@keydown.right.native="onTabNavigate(index + 1)"
									@keydown.home.native="onTabNavigate(0)"
									@keydown.end.native="onTabNavigate(navItems.length - 1)"
									@keydown.enter.native="tabNavigateTo(index)"
									@click="tabNavigateTo(index)"
									data-toggle="tab"
									role="tab"
									:aria-controls="item.ariaControls"
									:aria-selected="(currentPath === item.to || (item.to === '/goals' && currentPath.includes('/goals'))) ? 'true' : 'false'"
									:to="item.to">
								<span>{{ item.label }}</span>
							</router-link>
						</div>
					</div>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>


import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
			showOverflow: true,
			currentPath: window.location.pathname,
			menuOpen: false,
			navItems: [
				{
					label: 'Live Counter',
					to: '/',
					id: 'nav-live-counter-tab',
					ariaControls: 'nav-live-counter'
				},
				{
					label: 'Total Value Delivered',
					to: '/tvd',
					id: 'nav-total-value-delivered-tab',
					ariaControls: 'nav-tvd'
				},
				{
					label: '2030 Goals',
					to: '/goals',
					id: 'nav-goals-tab',
					ariaControls: 'nav-goals'
				}
			],
		}
	},
	watch: {
		'$route': function (to, from) {
			if (to.path !== from.path) {
				this.currentPath = to.path
			}
		},
	},
	computed: {
		...mapGetters([
			'iframe'
		]),
	},
	methods: {
		showOverlay: function (key) {
			this.$store.commit('setOverlayKey', key);
			this.menuOpen = false;
		},
		toggleOverflowMenu: function () {
			if (this.menuOpen) {
				this.menuOpen = false;
			} else {
				this.menuOpen = true;
			}
		},
		onTabNavigate: function (index) {
			if (index < 0) {
				index = this.navItems.length - 1;
			} else if (index >= this.navItems.length) {
				index = 0;
			}

			const navItem = this.navItems[index];
			if (this.$route.path !== navItem.to) {
				this.$router.push(navItem.to).then(() => {
					this.$nextTick(() => {
						this.$refs.navItems[index].$el.focus();
					});
				});
			}
		},
		tabNavigateTo: function (index) {
			const navItem = this.navItems[index];
			if (this.$route.path !== navItem.to) {
				this.$router.push(navItem.to).then(() => {
					this.$nextTick(() => {
						this.$refs.navItems[index].$el.focus();
					});
				});
			}
		},
	},
	components: {}
}
</script>
