import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';
import URL from '@/util/url.js';

Vue.use(VueRouter)


function loadView(view) {
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    return () => import( /* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [{
        path: '/',
        name: 'Home',
        meta: {
            vuexModel: '',
        },
        component: loadView('ImpactCounterHome')
    },
    {
        path: '/tvd',
        name: 'Total Value Delivered',
        meta: {
            vuexModel: 'tvd',
        },

        component: loadView('TotalValueDeliveredHome')
    },
    {
        path: '/goals',
        name: 'Goals',
        meta: {
            vuexModel: 'goalsWater',
        },

        component: loadView('GoalsHome'),
        children: [{
                path: 'food',
                name: 'Food',
                component: loadView('GoalsHome'),
                meta: {
                    vuexModel: 'goalsFood',
                },
            },
            {
                path: 'health',
                name: 'Health',
                component: loadView('GoalsHome'),
                meta: {
                    vuexModel: 'goalsHealth',
                },
            },
            {
                path: 'climate',
                name: 'Climate',
                component: loadView('GoalsHome'),
                meta: {
                    vuexModel: 'goalsClimate',
                },
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    //linkExactActiveClass : 'active',
    //linkActiveClass : 'active',
    routes
});

router.onReady((route) => {
    store.commit('setNamespace', route.meta.vuexModel)
    router.afterEach((to, from) => {
        if(to.path !== from.path){
            store.commit('setNamespace', to.meta.vuexModel)
        }
    });
})




export default router