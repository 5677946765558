var moment = require('moment');

module.exports = {
    secondsInAYear: 525600 * 60,

    start: moment([moment().year(), 0, 1]),

    entered: moment(),

    diff: function () {
        return moment().diff(this.start, 'seconds');
    },

    sinceUser: function () {
        return moment().diff(this.entered, 'seconds');
    }
};