import queryString from 'query-string';
import {extend, omit, isNull, pick} from 'lodash';

let url = {
    hash : {},
    protected : ['view', 'type'],
    init: function () {

        let parsedHash = queryString.parse(location.hash)
        _.each(parsedHash, (value, key) =>{
            if(_.isArray(value)){
                parsedHash[key] = _.first(value)
            }
        });
        this.hash = parsedHash;
        return this;
    },
    setHash: function (object) {
        extend(this.hash, object);
        location.hash = queryString.stringify(omit(this.hash, isNull));
        url.init();
    },
    reset: function() {
        let protect = queryString.stringify(pick(this.hash, this.protected));
        history.replaceState(null, null, (protect ? `#${protect}` : " "));
        url.init();
    },
    parse: function(hash){
        return queryString.parse(hash);
    },
    drop : function(hash, obj){
        let h = queryString.parse(hash)
        return queryString.stringify(omit(h, obj));
    }
};


export default url.init();