const state = {
    widget: 0
}

const getters = {
    widget: state => state.widget
}

const mutations = {
    setMyWidget(state, boolean){
        state.widget = boolean
    }
}


export default {
    state,
    mutations,
    getters,
};