const state = {
    availableSpace: 0
}

const getters = {
    availableSpace: state => state.availableSpace
}

const mutations = {
    setAvailableSpace(state, number){
        //console.log('av space set')
        state.availableSpace = number
    }
}

const actions = {
    setAvailableSpaceState({commit}, number){
        commit('setAvailableSpace', number)
    }
}

export default {
    state,
    mutations,
    getters,
    actions
};