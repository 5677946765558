import Dates from '@/util/date.js';

const state = {
    data : [],
    applications : [], //all applications from the json
    application : {}, //the application currently selected

    records : [], //the possible records
    record: {}, //selected record

    technologies : [], //the possible technologies
    technology: {}, //the technology currently selected

    regions : [], // the possible regions
    region : {}, //selected region

    impactAreas : [], //the possible impact areas
    impactArea : {}, // selected imapact area

    metricUnits : [], //the possible metric units
    metricUnit : {}, //the selected metric unit

    factoids : [
        // statconversion = 1 / conversion * stat;
        // 289 gallons per person per year was provided as the stat
        // Official Excel spreadsheet shows that a person actually uses 289.26834 gallons per year
        // 3 liters a day * 365 = 1095 liters a year
        // 1095 liters = 289.26834 gallons
        {resource: 'Water', conversion: 1, stat: 289.26834, unit: 'People', id: 0, text: 'equivalent to the annual drinking water needs of more than', reference: ''},
        // 750 therms per person per year
        {resource: "Energy", conversion: 0.00001, stat: 750, unit: 'People', id: 1, text: 'equivalent to the annual energy use of', reference: ''},

        {resource: 'Greenhouse Gases', conversion: 0.02083333612, stat: 1, unit: 'Trees', id: 2, text: 'equivalent to the carbon dioxide absorbed over 10 years by', reference: ''},

        // 1.2 kg per person per day = 438 kg per year
        // .4835923 converts from kg to lbs
        // This is a more accurate conversion that does not match the official Excel spreadsheet
        //{resource: 'Waste', conversion: 0.453592373672315, stat: 438, unit: 'People', id: 3, text: 'equivalent to the annual waste of', reference: ''}

        // 1.2 kg per person per day * 365 = 438 kg per year
        // 438 kg = 965.626 lbs
        // 965.626 lbs / 2000 lbs = .4828125 tons
        // The official Excel spreadsheet shows a person creates .48 tons of waste per year
        // 1 / (2000 * 0.48) = 0.00104
        {resource: 'Waste', conversion: 0.0010416666667, stat: 1, unit: 'People', id: 3, text: 'equivalent to the annual waste of', reference: ''}
    ],

    factoid : {},
    factoidCount : {},

    periods : [], //the possible periods

    period : {}, //the selected period

    runRate : false,
    count : 0,  //the final computed count,  aka "rolling" numbers


}

const getters = {
    data :         state => state.data,
    applications : state => state.applications,
    application :  state => state.application,
    records :      state => state.records,
    record :       state => state.record,
    technologies : state => state.technologies,
    technology :   state => state.technology,
    regions :      state => state.regions,
    region :       state => state.region,
    impactAreas :  state => state.impactAreas,
    impactArea :   state => state.impactArea,
    metricUnits :  state => state.metricUnits,
    metricUnit :   state => state.metricUnit,
    factoids :     state => state.factoids,
    factoid :      state => state.factoid,
    factoidCount : state => state.factoidCount,
    periods :      state => state.periods,
    period :       state => state.period,
    runRate :      state => state.runRate,
    count :        state => state.count,
}

const mutations = {
    setData(state, data){
        state.data = data;
        state.applications = _.orderBy(data.applications, ['sortOrder']);
    },
    setApplication(state, application){
        state.application = application;

    },
    setRecords(state, application){
        state.records = _.filter(state.data.records, {'application' : application});
    },

    setRecord(state){
        state.record = _.find(state.records, {technology: state.technology, region: state.region, impactArea: state.impactArea});
    },

    setTechnologies(state, records){
        state.technologies = _.uniqBy(_.orderBy(_.map(records, function(p){
            return _.find(state.data.technologies, {id: p.technology});
        }), ['sortOrder']), 'id');
    },
    setTechnology(state, technology){
        state.technology = technology;
    },
    setRegions(state, technology){
        let regions = new Set();

        _.each(state.records, function(p){
            if(p.technology == state.technology){
                regions.add(_.find(state.data.regions, {id : p.region}));
            }
        });

        state.regions = _.orderBy(Array.from(regions), 'sortOrder');



    },
    setRegion(state, region){
        state.region = region;
    },

    setImpactAreas(state, technology){
         let impact = new Set();
        _.each(state.records, function(p){
            if(p.technology == state.technology && p.region == state.region){
                impact.add(_.find(state.data.impactAreas, {id : p.impactArea}));
            }
        });

        state.impactAreas = _.orderBy(Array.from(impact), 'sortOrder');
    },

    setImpactArea(state, area){
        state.impactArea = area;
    },

    setMetricUnits(state){

        let units =  _.filter(state.data.metricUnits, {impactArea : state.record.impactArea});

        //we drop 'people' for some reason here.  Must be a business rule that I'm nor aware of the reasoning
        _.remove(units, {name : 'People'});


        state.metricUnits = _.orderBy(units, 'sortOrder');
    },

    setMetricUnit(state, unit){

        if(!_.isObject(unit)){
            unit = _.find(state.metricUnits, { id : unit });
        }
        state.metricUnit = unit;
    },

    setFactoid(state){

        let currentImpactAreaName = _.get(_.find(state.impactAreas, {id : state.impactArea}), "name");
        let fact = _.find( state.factoids, { resource : currentImpactAreaName } );
        state.factoid = fact;
    },

    setPeriods(state){
        state.periods = [
            {name: 'This year', id: 0},
            {name: "Since you've been here", id: 1},
            {name: 'Since introduction', id: 2},
            {name: 'Last year', id: 3}
        ]
    },

    setPeriod(state, period){
        state.period =  parseInt(period);
    },

    setRunRate(state){
        let unit = state.metricUnit;
        if(!unit){
            return;
        }
        state.runRate = (state.record.runRate * unit.conversionFactor) / Dates.secondsInAYear;
    },


    setCount(){
        // when we have a run rate & a time period we can calculate
        let sessionStart;
        let unit = state.metricUnit;

        switch (state.period) {
            case 0:
                //since beginning of the year
                sessionStart = (state.runRate * Dates.diff());
                break;
            case 1:
                //since the user has been here
                sessionStart = state.runRate * Dates.sinceUser();
                break;
            case 2:
                //since program start
                let start = (state.record.startingPoint * unit.conversionFactor);

                //the starting number + beginning of this year
                sessionStart = start + (state.runRate * Dates.diff());
                break;
            case 3:
                //static prior year
                state.runRate = 0;
                //we don't care about now, just show the old static number of last year
                sessionStart = state.record.static * unit.conversionFactor;
                break;
        }

        state.count = sessionStart;
    }

}

const actions = {
    getData({commit}){
         return axios.get('/updated-data-2024-05-09.json')
        .then(function(response) {
            //console.log(response.data.applications)
            if(!response.data){
                commit('setData', null);
                return
            }
            commit('setData', response.data);
        })
        //ERROR
        .catch(function(error) {
            commit('setData', null);
            console.log(error);
        });
    },
    setApplicationState({commit}, application){
        commit( 'setApplication',  application );
        commit( 'setRecords',      application );
        commit( 'setTechnologies', state.records );

    },
    setTechnologyState({commit}, technology){
        commit( 'setTechnology',  technology );
        commit( 'setRegions');
    },
    setRegionState({commit}, region){
        commit( 'setRegion' , region);
        commit( 'setImpactAreas');

    },
    setMetricUnitState({commit}, unit){
        commit( 'setMetricUnit', unit );
        commit( 'setRunRate' );
        commit( 'setCount' );

    },
    setImpactAreaState({commit}, area){
        commit ( 'setImpactArea', area );
        commit ( 'setRecord' );
        commit ( 'setMetricUnits' );
        commit ( 'setPeriods');
        commit ( 'setFactoid');

    },
    setPeriodState({commit}, period){
        commit( 'setPeriod', period );
        commit( 'setRunRate' );
        commit( 'setCount' )
    },
    getCounterInterval({commit}, value){
        state.count = state.count + (state.runRate / 20) //this is ran every 50ms.  1000ms in a second.  So 1000/50 = 20
    },
    getFactoidInterval({commit}, value){
       if(!state.metricUnit){
            return;
       }

       state.factoidCount =  Math.round(((state.count / state.metricUnit.conversionFactor) * state.factoid.conversion) / state.factoid.stat);
    }

}

export default {
    state,
    mutations,
    getters,
    actions
};