const state = {
    namespace : ''
}

const getters = {
    namespace : state => state.namespace,
}

const mutations = {
    setNamespace(state, namespace){
        state.namespace = (namespace ? `${namespace}/`: "");
    }
}

export default {
    state,
    mutations,
    getters
};