<template>
    <div :title="selectedName">
        <label for="application">Application</label>
            <div :class="[multipleOptions ? 'select' : 'one_option select']">
            <select id="application" v-model="selected">
                <option disabled="" value="default">Select an application</option>
                <option v-for="option in applications" v-bind:value="option.id">{{option.name}}</option>
            </select>
            <div class="select-styled-container"><div class="select-styled">{{selectedName}}</div></div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url.js';
export default {
     data : function () {
        return {
            multipleOptions : true, //todo

        }
    },
    mounted(){

    },
    computed : {
        ...mapGetters([
            'applications',
            'application'
        ]),
        selectedName :function() {

            if(!this.applications){
                return;
            }

            if(!this.selected || this.selected == 'default'){
                return 'Select an application';
            }

            let match = _.first(_.filter(this.applications, { id : this.selected }));

            if(!match){
              return 'Select an application';
            }

            return match.name;
        },
        selected : {
            get () {
                return this.application;
            },
            set (value) {
                this.setApplicationState(value);
                URL.setHash({application: value});
            }
        }
    },
    methods : {
        ...mapActions(
            [
                //'getData', //initial data from json
                'setApplicationState' //setting are application and therefore setting the relationships
            ]
        )
    },
}
</script>